<template>
  <div class="q-pa-md ">
    <q-btn
      class="full-width"
      @click="bar = true"
      unelevated
      color="dark"
      :loading="recuse_in_request"
      :label="$t('visualizar')"
      style="border-radius: 10px;"
      :disabled="data.can_rescue"
    />

    <q-dialog v-model="bar" full-width :maximized="true">
      <q-card :style="`background-color: ${layout.body_background};`">
        <q-bar class="bg-dark text-white padding-title">
          <q-icon name="redeem" />
          <div
            class="text-bar"
            v-html="
              $t('my_points.award.select_u_option', {
                type: option.title,
                value: toMoneyLabel(data.point.toFixed(2)),
              })
            "
          ></div>
          <q-space />
          <q-btn flat icon="close" v-close-popup>
            <q-tooltip>{{ $t("global.close") }}</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <reward-card-information
            :data="data.point.toFixed(2)"
            v-if="!disabled"
          />
          <div
            class="bg-red-4 q-ma-xs default-rounded q-pa-xs text-white"
            v-if="disabled"
          >
            <ul>
              <li
                v-if="data.can_rescue"
                v-html="
                  $t('my_points.award.min_rescue_internal', {
                    value: toMoneyLabel(data.min_value),
                  })
                "
              />
              <li
                v-if="data.doc_pend"
                v-html="$t('my_points.award.doc_pend_rescue_internal')"
              />
            </ul>
          </div>

          <div class="row q-ma-md" v-if="showFilters">
            <div class="col-12 col-md-3">
              <q-input
                outlined
                bg-color="white"
                bottom-slots
                v-model="text_search"
                label="Busca"
                style="width: 99%"
              >
                <template v-slot:append>
                  <q-icon
                    v-if="text_search !== ''"
                    name="close"
                    @click="text_search = ''"
                    class="cursor-pointer"
                  />
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
            <div class="col-12 col-md-3 itens-center">
              <q-select
                outlined
                clearable
                bg-color="white"
                v-model="suplierFilter"
                :options="getGiftCardSuppliers"
                option-label="fornecedor_nome"
                option-value="fornecedor_id"
                label="Fornecedores"
                class="selectFilter"
              />
            </div>
            <div class="col-12 col-md-2 itens-center">
              <q-select
                outlined
                clearable
                bg-color="white"
                v-model="categoryFilter"
                :options="getGiftCardCategories"
                option-label="categoria_nome"
                option-value="categoria_id"
                label="Categorias"
                class="selectFilter"
              />
            </div>
            <div class="col-12 col-md-2">
              <q-input
                outlined
                type="number"
                :debounce="1000"
                bg-color="white"
                label="Valor com taxa"
                v-model="valueWithTax"
                class="selectFilter"
              >
                <template v-slot:prepend>
                  <q-btn
                    round
                    dense
                    icon="remove"
                    color="black"
                    @click="sumOrMinus('remove')"
                  />
                </template>
                <template v-slot:append>
                  <q-btn
                    round
                    dense
                    icon="add"
                    color="black"
                    @click="sumOrMinus('add')"
                  />
                </template>
              </q-input>
            </div>
            <div class="col-12 col-md-2">
              <q-btn
                color="dark"
                :loading="loading"
                :label="$t('Aplicar filtro')"
                class="q-pa-md full-width"
                @click="filterGifts"
              />
            </div>
          </div>
          <hr />
          <div>
            <q-scroll-area
              v-if="!loading"
              :thumb-style="{ width: '5px' }"
              :content-style="{ width: '5px' }"
              :content-active-style="{ color: 'black' }"
              style="height: 72vh"
            >
              <div class="row q-pb-lg" v-if="getGiftCards.length > 0">
                <reward-item
                  :data="data"
                  :item="item"
                  :option="option"
                  :key="`option-${item.id}`"
                  v-for="item in getGiftCards"
                />
              </div>
              <div
                v-else
                class="bg-grey-2 text-center q-pa-xl"
                v-html="$t('my_points.no_gift')"
              />
            </q-scroll-area>
            <div class="text-center q-pa-xl full-height" v-else>
              <q-spinner
                color="grey-5"
                class="q-mb-xl q-mt-lg"
                size="30em"
                :thickness="10"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import RewardCardInformation from "./reward-card-information.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { ref, watch, onMounted } from "vue";
import RewardItem from "./reward-item";
import helpers from "@/shared/helpers";

const { getCustomLayout } = helpers.customLayout,
  { useActions, useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "RewardGiftModal",

  components: { RewardCardInformation, RewardItem },

  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
    recuse_in_request: {
      required: true,
      type: Boolean,
    },
    option: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const bar = ref(false),
      loading = ref(false),
      showFilters = ref(true),
      text_search = ref(""),
      suplierFilter = ref(""),
      categoryFilter = ref(""),
      valueWithTax = ref(props.data.point.toFixed(2));

    const searchParams = ref({ preco_ate: props.data.point.toFixed(2) });

    const filterGifts = async () => {
      loading.value = true;
      await fetchGiftCards(searchParams.value).finally(
        () => (loading.value = false)
      );
    };

    const {
        fetchGiftCards,
        fetchGiftCardSuppliers,
        fetchGiftCardCategories,
        fetchGiftCardDepartments,
      } = useActions([
        "fetchGiftCards",
        "fetchGiftCardSuppliers",
        "fetchGiftCardCategories",
        "fetchGiftCardDepartments",
      ]),
      {
        getGiftCards,
        getGiftCardSuppliers,
        getGiftCardCategories,
        getGiftCardDepartments,
      } = useGetters({
        getGiftCards: "getGiftCards",
        getGiftCardSuppliers: "getGiftCardSuppliers",
        getGiftCardCategories: "getGiftCardCategories",
        getGiftCardDepartments: "getGiftCardDepartments",
      });

    const layout = getCustomLayout();

    // Função para definir os parâmetros de pesquisa
    const setSearchParams = () => {
      if (valueWithTax.value !== "") {
        searchParams.value["preco_ate"] = valueWithTax.value;
      } else delete searchParams.value.preco_ate;

      if (text_search.value !== "") {
        searchParams.value["q"] = text_search.value;
      } else delete searchParams.value.q;

      if (suplierFilter.value) {
        searchParams.value["fornecedor_id"] = suplierFilter.value.fornecedor_id;
      } else delete searchParams.value.fornecedor_id;

      if (categoryFilter.value) {
        searchParams.value["categoria_id"] = categoryFilter.value.categoria_id;
      } else delete searchParams.value.categoria_id;
    };

    // Função para carregar dados
    const loadData = async () => {
      loading.value = true;
      await Promise.all([
        filterGifts(),
        fetchGiftCardSuppliers(),
        fetchGiftCardCategories(),
        fetchGiftCardDepartments(),
      ]);
      loading.value = false;
    };

    // Executar as funções quando o componente é montado
    onMounted(async () => await loadData());

    // Observadores para monitorar mudanças nos valores
    watch([valueWithTax, text_search, suplierFilter, categoryFilter], () =>
      setSearchParams()
    );

    return {
      bar,
      layout,
      loading,
      showFilters,
      text_search,
      valueWithTax,
      toMoneyLabel,
      suplierFilter,
      categoryFilter,

      getGiftCards,
      getGiftCardCategories,
      getGiftCardDepartments,
      getGiftCardSuppliers,

      filterGifts,
      sumOrMinus: (type) => {
        const minValue = parseFloat(props.data.option.min_rescue_value);

        const currentValue = parseFloat(valueWithTax.value);
        const increment = type === "add" ? 50 : -50;

        // Verifique se o currentValue é igual ao minValue e o tipo é "add" (soma)
        if (currentValue === minValue && type === "add") {
          valueWithTax.value = (50).toFixed(2);
        } else {
          let newValue = (currentValue + increment).toFixed(2);

          // Certifique-se de que newValue nunca seja menor que minValue
          if (parseFloat(newValue) < minValue) {
            newValue = minValue.toFixed(2);
          }

          valueWithTax.value = newValue;
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.container-width {
  width: 80% !important;
}
.padding-title {
  padding: 1.5em;
}
.rouded-border {
  border-radius: 10px;
}
.button-filter {
  background-color: rgb(185, 185, 185);
}
.selectFilter {
  width: 98%;
}
.text-bar {
  font-size: 13pt;
}

@media (max-width: 1024px) {
  .selectFilter {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .text-bar {
    font-size: 9pt;
  }
}
</style>
