<template>
  <q-dialog>
    <q-card width-container class="q-pa-lg">
      <div class="row justify-center">
        <img :src="image" style="width: 20%" />
      </div>
      <div class="row justify-center q-mt-sm">
        <p>{{ message }}</p>
      </div>
      <q-card-actions
        class="row justify-center"
        style="width: 100%; padding-top: 1rem"
      >
        <q-btn
          color="black"
          :label="$t('global.confirm')"
          @click="onSubmit()"
          :loading="loading"
        />
        <q-btn :label="$t('global.cancel')" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
export default {
  name: "rewardConfirmationModalComponent",

  props: {
    image: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["submit"],

  setup(props, { emit }) {
    const onSubmit = () => emit("submit");

    return {
      onSubmit,
    };
  },
};
</script>
