<template>
  <div class="q-pa-md q-gutter-sm">
    <q-btn
      unelevated
      color="dark"
      class="full-width"
      @click="modalView = true"
      :disable="disabled"
      :loading="recuse_in_request"
      :label="$t('visualizar')"
      style="border-radius: 10px;"
    />

    <q-dialog v-model="modalView">
      <q-card>
        <q-bar class="bg-dark text-white">
          <q-icon name="whatshot" />
          <div class="text-bar">
            {{ $t("my_points.award.reward_option", { type: option.title }) }}
          </div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip>{{ $t("global.close") }}</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <div class="q-pa-md">
            <div class="bg-grey-12 q-pt-lg q-pb-sm default-rounded">
              <div class="text-center">
                <q-img width="6em" :src="option.image" />
              </div>
              <div class="q-mt-md text-weight-bolder">
                <p
                  style="
                    text-align: center;
                    font-size: 12pt;
                    padding-left: 5px;
                    padding-right: 5px;
                  "
                >
                  {{ option.incentive_string }}
                </p>
              </div>
            </div>
            <div class="q-mt-md">
              <p
                class="text-weight-bolder"
                style="text-align: center; font-size: 12pt"
              >
                {{
                  $t("my_points.award.reward_alert_modal_text", {
                    type: option.title,
                  })
                }}
              </p>
            </div>
          </div>
          <div align="right">
            <q-btn
              class="default-rounded text-weight-regular"
              @click="select(null)"
              unelevated
              color="dark"
              :loading="in_request"
              :label="$t('my_points.award_rescue')"
            />
            <q-btn
              unelevated
              flat
              color="blue"
              :label="$t('global.cancel')"
              class="default-rounded text-weight-regular"
              v-close-popup
            />
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="modalValidatePix"
      v-if="Object.values(pix_account).length"
    >
      <q-card>
        <q-bar class="bg-dark text-white">
          <q-icon name="vpn_key" />
          <div class="text-bar">
            {{ $t("Validar Pix") }}
          </div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip>{{ $t("global.close") }}</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <q-form class="q-gutter-md q-mt-sm" @submit="fetchRewardMoney">
            <div class="q-pr-sm q-pl-sm">
              <div class="bg-grey-4 q-pa-md default-rounded">
                Para concluirmos a operação, por favor, informe a chave pix
                cadastrada.
              </div>
              <q-input
                filled
                clearable
                class="q-mb-sm q-mt-md"
                v-model="pixValue"
                mask="##.###.###/####-##"
                :rules="[
                  (v) => !!v || $t('validations.required'),
                  (v) => validatePix({ type: pix_account.type, content: v }),
                ]"
                lazy-rules="ondemand"
              />
            </div>
            <div align="right" class="q-mt-sm">
              <q-btn
                type="submit"
                class="default-rounded text-weight-regular"
                unelevated
                color="dark"
                :loading="in_request"
                :label="$t('global.confirm')"
              />
              <q-btn
                unelevated
                flat
                color="blue"
                :label="$t('global.cancel')"
                class="default-rounded text-weight-regular"
                v-close-popup
              />
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { useRouter } from "vue-router";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("rescue_award");
const accountNamespace = createNamespacedHelpers("account");

export default {
  name: "RewardMoney",

  props: {
    disabled: {
      required: true,
      type: Boolean,
    },
    option: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter(),
      { rescueReward } = useActions(["rescueReward"]);

    const { pix_account } = accountNamespace.useGetters({
      pix_account: "getPartnerPixAccount",
    });

    const { validatePix } = accountNamespace.useActions({
      validatePix: "validatePix",
    });

    const canRescueValue = () => props.data.amount >= props.data.min_value;

    const select = (item = null) => {
      item;
      // if (Object.values(pix_account.value).length === 0) {
      //    fetchRewardMoney();
      // } else {
      // pixValue.value = pix_account.value.content;
      // modalValidatePix.value = true;
      // modalView.value = false;
      // }
      fetchRewardMoney();
    };

    const fetchRewardMoney = () => {
      if (canRescueValue()) {
        let data = {
          amount: props.data.point,
          reward_option_id: props.option.reward_option_id,
          external_id: null,
          quantity: 1,
        };
        rescueReward(data)
          .then(() => {
            notifySuccess();
            router.push({
              name: "my-points",
            });
          })
          .catch(() => notifyError());
      }
    };

    const getPixMask = (type) => {
      if (type === "cpf") return "###.###.###-##";
      if (type === "cnpj") return "##.###.###/####-##";
      if (type === "phone") return "(##) #####-####";
      return "";
    };

    const pixValue = ref(""),
      modalView = ref(false),
      modalValidatePix = ref(false);

    return {
      pixValue,
      pix_account,
      modalView,
      modalValidatePix,
      toMoneyLabel,
      select,
      canRescueValue,
      getPixMask,
      validatePix,
      fetchRewardMoney,
    };
  },
};
</script>
