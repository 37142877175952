<template>
  <div class="bg-white default-rounded q-ma-sm q-pb-sm">
    <h6
      class="q-pt-lg  q-pl-lg q-mt-md text-grey-10 text-weight-light"
      v-html="$t('my_points.award.description')"
    />
    <div :class="{ 'q-pb-sm': !hasPointToRescue }" v-if="!hasPointToRescue">
      <p
        style="font-size: 13pt"
        class="text-center text-red text-weight-light"
        v-html="$t('my_points.award.cannotRescue')"
      />
      <p
        style="font-size: 11pt; margin-top: -1em"
        class="text-center text-dark text-weight-light"
        v-html="$t('my_points.award.cannotRescueLabel')"
      />
    </div>

    <div class="row q-col-gutter-md q-pa-md">
      <template v-if="options.length < 1">
        <reward-skeleton v-for="skeleton in 3" :key="skeleton" />
      </template>
      <div
        class="col-xs-12 col-sm-6 col-md-4 col-lg-3  q-ml-auto q-mr-auto"
        v-else
        v-for="option in options"
        :key="'reward-' + option.id"
      >
        <RewardItemsList
          :option="option"
          :type="option.type"
          :title="option.title"
          :amount="option.value"
          :doc_pend="option.doc_pend"
          :point="option.total_points"
          :descriptions="option.details"
          :min_value="option.min_rescue_value"
          :receiver="option.incentive_string"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import RewardItemsList from "./RewardItemsList";
import RewardSkeleton from "./rewardSkeleton";

const { useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "Rewards",

  components: { RewardSkeleton, RewardItemsList },

  setup() {
    const { options, hasPointToRescue } = useGetters({
      hasPointToRescue: "hasPointToRescue",
      options: "getRewardOptions",
    });

    return {
      options,
      toMoneyLabel,
      hasPointToRescue,
    };
  },
};
</script>
