<template>
  <q-card-section>
    <div class="row">
      <div class="col-12 col-md-4 image-align">
        <img
          :src="getImages(currentSelectedOption.fotos)"
          class="image-width"
        />
      </div>
      <div class="col-12 col-md-8">
        <div class="column justify-start">
          <p
            v-html="currentSelectedOption.produto_nome"
            class="text-weight-bolder text-uppercase text-grey-8 prod-title q-mb-none"
          />
          <p
            class="q-mb-lg text-weight-bolder q-mb-none"
            v-html="currentSelectedOption.categoria_nome"
          />
          <div class="q-mb-md">
            <slot />
          </div>
          <p
            class=" text-weight-bolder text-uppercase text-grey-8 q-mb-none"
            v-html="$t('my_points.reward_pre_paid_card.description')"
          />
          <div
            v-if="currentSelectedOption.descricao.length < 200"
            class="text-justify prod-description"
            v-html="currentSelectedOption.descricao"
          />
          <div
            v-else
            class="text-justify prod-description"
            v-html="
              currentSelectedOption.descricao &&
              isCompacted.moreInformation === true
                ? currentSelectedOption.descricao.substr(0, 150) + '...'
                : currentSelectedOption.descricao
            "
          />
          <div
            class="q-mb-sm"
            v-if="currentSelectedOption.descricao.length > 200"
          >
            <span
              v-if="isCompacted.moreInformation"
              @click="
                isCompacted.moreInformation = !isCompacted.moreInformation
              "
              style="cursor: pointer"
              v-html="$t('global.show_more')"
              class="text-blue-8 text-uppercase text-weight-bolder"
            />
            <span
              v-else
              style="cursor: pointer"
              @click="
                isCompacted.moreInformation = !isCompacted.moreInformation
              "
              class="text-blue-8 text-uppercase text-weight-bolder"
              v-html="$t('global.show_less')"
            />
          </div>
        </div>
      </div>
    </div>
  </q-card-section>
</template>

<script>
import { ref } from "vue";

export default {
  name: "prepaidCardInfos",

  props: {
    currentSelectedOption: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const isCompacted = ref({
      moreInformation: true,
      moreInformationAdditional: true,
      descriptionDetails: true,
    });

    const getImages = (images) =>
      images != null ? JSON.parse(images).g : "/awards-icons/img_not_found.png";

    return {
      isCompacted,
      getImages,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-align {
  text-align: center;
}
.image-width {
  width: 70%;
  margin-top: -10px;
}
</style>
