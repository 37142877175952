<template>
  <div v-if="!in_request && last_reward_opened.length" class="q-ma-sm q-mb-lg">
    <p class="q-mt-lg text-blue-grey-7 text-weight-medium text-subtitle1">
      {{ $t("my_points.award.reward_opened_title") }}
    </p>
    <OpenedItem
      v-for="(reward, key) in last_reward_opened"
      :reward="reward"
      :key="key"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import OpenedItem from "./OpenedItem";

const { useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "RewardOpenedContainer",

  components: { OpenedItem },

  setup() {
    const { in_request, last_reward_opened } = useGetters({
      in_request: "isInRequest",
      last_reward_opened: "getLastRewardOpened",
    });

    return {
      in_request,
      last_reward_opened,
    };
  },
};
</script>
