<template>
  <div class="q-pa-md q-gutter-sm">
    <q-btn
      color="black"
      @click="openModal()"
      :disable="!canRescueValue()"
      :loading="recuse_in_request"
      :label="$t('visualizar')"
      class="full-width"
      style="border-radius: 10px;"
    />
  </div>

  <q-dialog v-model="dialog">
    <q-card class="width-container" v-if="currentSelectedOption">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn
          flat
          round
          dense
          icon="close"
          v-close-popup
          class="text-red text-weight-bolder"
        />
      </q-card-section>

      <prepaidCardInfos :currentSelectedOption="currentSelectedOption">
        <q-btn-group>
          <q-btn
            flat
            icon="remove"
            color="white"
            class="bg-black"
            @click="
              changeCurrentSelectedOption(currentSelectedOption.preco, 'add')
            "
            :disabled="verifyBtnRemove(currentSelectedOption.preco)"
          />
          <q-btn
            flat
            disabled
            color="white"
            :label="currentSelectedOption.preco"
            class="bg-black"
          />
          <q-btn
            flat
            icon="add"
            color="white"
            class="bg-black"
            @click="
              changeCurrentSelectedOption(currentSelectedOption.preco, 'remove')
            "
            :disabled="verifyBtnPlus(currentSelectedOption.preco)"
          />
        </q-btn-group>
      </prepaidCardInfos>

      <!-- 
        Área opções
      -->
      <div class="row justify-center text-weight-medium">
        <q-option-group
          inline
          color="black"
          v-model="group"
          :options="options"
          v-if="rechargeOptions"
        />
      </div>

      <!-- 
        Área de resgate 
      -->
      <prepaidRescue
        v-if="group == 0"
        :data="data"
        :option="option"
        :doc_pend="doc_pend"
        :indexes="PrepaidIndexes"
        @submitForm="confirmModal"
        :selectedOption="currentSelectedOption"
      />

      <!-- 
        Área de recarga 
      -->
      <prepaidRecharge
        v-if="group == 1 && rechargeIndexes"
        :data="data"
        :option="option"
        :doc_pend="doc_pend"
        :indexes="rechargeIndexes"
        @submitForm="confirmModal"
        :selectedOption="currentSelectedOption"
      />
    </q-card>
  </q-dialog>
  <reward-confirmation-modal-component
    v-model="confirmationDialog"
    :image="'/present-icon.svg'"
    :message="$t('my_points.reward_gift.final_confirmation')"
    @submit="onSubmit(group)"
    :loading="loading"
  />
</template>

<script>
import rewardConfirmationModalComponent from "@/modules/main/components/rewardConfirmationModalComponent.vue";
import prepaidCardInfos from "./prepaidCardInfos.vue";
import prepaidRecharge from "./prepaidRecharge.vue";
import prepaidRescue from "./prepaidRescue.vue";

import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { useGetters, useActions } from "vuex-composition-helpers";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "rewardPrepaidCardComponent",

  components: {
    prepaidRescue,
    prepaidRecharge,
    prepaidCardInfos,
    rewardConfirmationModalComponent,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    recuse_in_request: {
      type: Boolean,
      required: true,
    },
    doc_pend: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n(),
      PrePaidCardInfo = ref(null),
      rechargeOptions = ref(null),
      PrepaidIndexes = ref(null),
      rechargeIndexes = ref(null),
      currentSelectedOption = ref(null),
      loading = ref(false),
      dataToSend = ref(null);

    const { getPrePaidCardInfo } = useGetters({
        getPrePaidCardInfo: "rescue_award/getPrePaidCardInfo",
      }),
      {
        rescueReward,
        fetchReward,
        prePaidCardRescue,
        fetchCanRescuePaidCard,
      } = useActions({
        rescueReward: "rescue_award/rescueReward",
        fetchReward: "rescue_award/fetchReward",
        prePaidCardRescue: "rescue_award/prePaidCardRescue",
        fetchCanRescuePaidCard: "rescue_award/fetchCanRescuePaidCard",
      });

    const isCompacted = ref({
      moreInformation: true,
      moreInformationAdditional: true,
      descriptionDetails: true,
    });

    const options = [0, 1].map((item) => ({
      label: t(`my_points.reward_pre_paid_card.op${item}label`),
      value: item,
    }));

    const group = ref(null),
      dialog = ref(false),
      confirm = ref(""),
      confirmationDialog = ref(false);

    const confirmModal = (values) => {
        dataToSend.value = values;
        confirmationDialog.value = true;
      },
      canRescueValue = () => props.data.amount >= props.data.min_value;

    const openModal = async () => {
      await prePaidCardRescue({
        value: props.option.total_points,
        several: true,
      }).then(() => {
        PrePaidCardInfo.value = getPrePaidCardInfo.value[0];
        rechargeOptions.value =
          getPrePaidCardInfo.value.length > 1
            ? getPrePaidCardInfo.value[1]
            : null;
        PrepaidIndexes.value = getPrePaidCardInfo.value[0].map(
          (element) => element.preco
        );
        rechargeIndexes.value =
          getPrePaidCardInfo.value.length > 1
            ? getPrePaidCardInfo.value[1].map((element) => element.preco)
            : null;
        group.value = 0;
        dialog.value = true;
      });
    };

    const verifyBtnPlus = (value) => {
        if (group.value == 0) return value == PrepaidIndexes.value[0];
        else return value == rechargeIndexes.value[0];
      },
      verifyBtnRemove = (value) => {
        if (group.value == 0)
          return value == PrepaidIndexes.value[PrepaidIndexes.value.length - 1];
        else
          return (
            value == rechargeIndexes.value[rechargeIndexes.value.length - 1]
          );
      };

    const changeCurrentSelectedOption = (value, addOrRemove) => {
      if (group.value == 0) {
        const currentIndex = PrePaidCardInfo.value.findIndex(
          (element) => element.preco == value
        );
        if (addOrRemove == "add")
          currentSelectedOption.value = PrePaidCardInfo.value[currentIndex + 1];
        else
          currentSelectedOption.value = PrePaidCardInfo.value[currentIndex - 1];
      } else {
        const currentIndex = rechargeOptions.value.findIndex(
          (element) => element.preco == value
        );
        if (addOrRemove == "add")
          currentSelectedOption.value = rechargeOptions.value[currentIndex + 1];
        else
          currentSelectedOption.value = rechargeOptions.value[currentIndex - 1];
      }
    };

    const onSubmit = () => {
      if (canRescueValue()) {
        loading.value = true;
        rescueReward(dataToSend.value)
          .then(() =>
            fetchReward().then(() => {
              notifySuccess();
              window.location.reload();
            })
          )
          .catch(() => notifyError())
          .finally(() => (loading.value = false));
      }
    };

    onMounted(() => fetchCanRescuePaidCard());

    watch(group, (value) => {
      if (value == 0) currentSelectedOption.value = PrePaidCardInfo.value[0];
      else currentSelectedOption.value = rechargeOptions.value[0];
    });

    return {
      group,
      dialog,
      options,
      confirm,
      loading,
      isCompacted,
      PrePaidCardInfo,
      confirmationDialog,
      rechargeOptions,

      PrepaidIndexes,
      rechargeIndexes,
      currentSelectedOption,

      onSubmit,
      openModal,
      confirmModal,
      canRescueValue,
      verifyBtnPlus,
      verifyBtnRemove,
      changeCurrentSelectedOption,
    };
  },
};
</script>

<style lang="scss" scoped>
.width-container {
  min-width: 60vw;
}

@media (max-width: 800px) {
  .width-container {
    min-width: 95vw;
  }
  .prod-description {
    width: 100%;
  }
}
</style>
