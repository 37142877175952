<template>
  <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3  q-ml-auto q-mr-auto">
    <q-item style="width: 200px; margin: 0 auto">
      <q-item-section>
        <q-item-label>
          <q-skeleton type="text" />
        </q-item-label>
        <q-item-section class="items-center q-ma-sm" avatar>
          <q-skeleton type="QAvatar" />
        </q-item-section>
        <q-item-label caption>
          <q-skeleton type="text" />
        </q-item-label>
      </q-item-section>
    </q-item>
    <div class="q-ma-lg">
      <q-skeleton type="text" />
      <q-skeleton type="text" />
      <q-skeleton type="text" />
    </div>

    <q-card-actions align="center" class="q-gutter-md text-center">
      <q-skeleton type="QBtn" />
    </q-card-actions>
  </div>
</template>

<style scoped lang="scss">
.reward {
  background: #cccccc30;
  border-radius: 9px;
  box-shadow: inset 0px 0px 0px 3px #f1f1f1;
  width: 100%;
  max-width: 400px;
}
</style>
