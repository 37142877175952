<template>
  <q-dialog v-model="dialog">
    <q-card class="width-container">
      <q-card-section class="row items-center q-pb-none">
        <q-space />
        <q-btn
          icon="close"
          class="text-red text-weight-bolder"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <q-card-section class="margin">
        <div class="row">
          <div class="col-12 col-md-4 image-align">
            <img :src="getImages(itemSelected.imagens)" class="image-width" />
          </div>
          <div class="col-12 col-md-8">
            <div class="column justify-start items-start">
              <span
                class="q-mb-lg text-uppercase text-weight-bolder text-grey-8"
              >
                {{
                  $t("my_points.reward_gift.gift_value", {
                    value: toMoneyLabel(itemSelected.preco),
                  })
                }}
              </span>
              <span
                class="text-weight-bolder text-uppercase text-grey-8 prod-title"
              >
                {{ itemSelected.produto_nome.split(" -")[0] }}
              </span>
              <span class="q-mb-lg text-weight-bolder">{{
                itemSelected.categoria_nome
              }}</span>

              <div class="text-justify prod-description">
                {{
                  isCompacted.moreInformation === true
                    ? itemSelected.descricao.substr(0, 150) + "..."
                    : itemSelected.descricao
                }}
              </div>
              <div class="q-mb-sm">
                <span
                  v-if="isCompacted.moreInformation"
                  @click="
                    isCompacted.moreInformation = !isCompacted.moreInformation
                  "
                  class="text-blue-8 text-uppercase text-weight-bolder"
                  style="cursor: pointer"
                >
                  {{ $t("global.show_more") }}
                </span>
                <span
                  v-else
                  @click="
                    isCompacted.moreInformation = !isCompacted.moreInformation
                  "
                  class="text-blue-8 text-uppercase text-weight-bolder"
                  style="cursor: pointer"
                >
                  {{ $t("global.show_less") }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <q-expansion-item
          class="q-mt-sm"
          expand-separator
          icon="add"
          v-if="verifyIfExistsInfos(itemSelected)"
          :label="$t('my_points.reward_gift.expansion_item_title')"
          @click="
            (isCompacted.moreInformationAdditional = !isCompacted.moreInformationAdditional),
              ableButtom1()
          "
        >
          <q-card>
            <q-card-section>
              <div
                class="modal-information-container-dropdown"
                v-for="(info, i) in informationAdditional(itemSelected)"
                :key="i"
              >
                <div v-if="info.length > 0">
                  <div
                    v-for="(dataInfo, i) in info"
                    :key="i"
                    class="
                      text-weight-bolder text-grey-8 text-uppercase text-justify
                      q-mb-sm
                    "
                  >
                    <div class="" v-html="dataInfo.chave" />
                    <p
                      class="text-justify q-mt-sm text-uppercase text-grey-8"
                      style="font-size: 9pt"
                      v-html="dataInfo.valor"
                    />
                  </div>
                </div>
                <div>
                  <div
                    class="
                      text-weight-bolder text-grey-8 text-uppercase text-justify
                      q-mb-sm
                    "
                    v-html="info.chave"
                  ></div>
                  <p
                    class="text-justify q-mt-sm text-uppercase text-grey-8"
                    style="font-size: 9pt"
                    v-html="info.valor"
                  />
                </div>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
        <q-expansion-item
          expand-separator
          icon="add"
          :label="$t('my_points.reward_gift.expansion_item_title2')"
          @click="
            (isCompacted.descriptionDetails = !isCompacted.descriptionDetails),
              ableButtom2()
          "
        >
          <q-card>
            <q-card-section>
              <div class="modal-information-container-dropdown">
                <div
                  class="
                    text-weight-bolder text-grey-8 text-uppercase text-justify
                    q-mb-sm
                  "
                >
                  {{ $t("my_points.award_info.deadline") }}
                </div>
                <p
                  class="text-justify q-mt-sm text-uppercase text-grey-8"
                  style="font-size: 9pt"
                >
                  {{ descriptionDetails(itemSelected).prazo }}
                </p>
                <div
                  class="
                    text-weight-bolder text-grey-8 text-uppercase text-justify
                    q-mb-sm
                  "
                >
                  {{ $t("my_points.award_info.validate") }}
                </div>
                <p
                  class="text-justify q-mt-sm text-uppercase text-grey-8"
                  style="font-size: 9pt"
                >
                  {{ descriptionDetails(itemSelected).validade }}
                </p>
                <div
                  class="
                    text-weight-bolder text-grey-8 text-uppercase text-justify
                    q-mb-sm
                  "
                >
                  {{ $t("my_points.award_info.description") }}
                </div>
                <p
                  class="text-justify q-mt-sm text-uppercase text-grey-8"
                  style="font-size: 9pt"
                >
                  {{ descriptionDetails(itemSelected).descricao }}
                </p>

                <div
                  class="
                    text-weight-bolder text-grey-8 text-uppercase text-justify
                    q-mb-sm
                  "
                >
                  {{ $t("my_points.award_info.use") }}
                </div>
                <p
                  class="text-justify q-mt-sm text-uppercase text-grey-8"
                  style="font-size: 9pt"
                >
                  {{ descriptionDetails(itemSelected).utilizacao }}
                </p>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-card-section>
      <q-card-section
        v-if="ableSendButtomVerify()"
        class="
          justify-center
          q-ml-md q-mr-md q-mb-sm
          bg-red-2
          text-red-10 text-center
        "
      >
        <q-icon name="warning" style="font-size: 16pt" class="q-pr-sm" />
        <span
          v-if="
            JSON.parse(itemSelected.informacoes_adicionais).Informacao !=
              undefined
          "
          v-html="
            $t('my_points.reward_gift.alert_to_show', {
              name: itemSelected.produto_nome.split(' -')[0],
            })
          "
        ></span>
        <span
          v-else
          v-html="$t('my_points.reward_gift.alert_to_show_secound')"
        ></span>
      </q-card-section>

      <q-card-section
        class="justify-center q-ml-md q-mr-md q-mb-sm bg-grey-2"
        style="border-radius: 10px"
      >
        <p class="q-mt-sm">
          <q-icon name="mail" style="font-size: 16pt" />
          {{ $t("my_points.reward_gift.confirm_email") }}
        </p>

        <p class="text-center text-weight-bolder">{{ emailHidden }}</p>
        <q-form @submit.prevent="confirmModal()" class="q-gutter-md q-mt-sm">
          <q-input
            filled
            ref="ageRef"
            type="email"
            label="Email"
            v-model="email"
            :rules="[
              (value) => emailMatchValidation(value, userEmail, 'email'),
            ]"
          />
          <q-toggle
            v-model="wasRead"
            :label="$t('my_points.reward_gift.confirm_check')"
          /><br />
          <p
            v-if="wasRead == false"
            style="font-size: 9pt"
            class="text-red-8 q-mt-sm q-ml-lg"
          >
            {{ $t("my_points.reward_gift.alerts.alert_check") }}
          </p>
          <div align="right">
            <q-btn
              color="black"
              type="submit"
              class="button-style"
              :label="$t('global.send')"
              :disabled="wasRead == false || !ableSendButtomVerify() == false"
            />
            <q-btn
              flat
              v-close-popup
              color="primary"
              @click="email = ''"
              :label="$t('global.cancel')"
              class="q-ml-sm button-style"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>

  <div class="col-12 col-md-3 q-mt-sm" v-if="item.preco != 0">
    <v-card
      :item="item"
      :data="data"
      :disabled="data.point < item.preco"
      v-on:openModal="openModal"
      @click="returnButtonEnable()"
      v-on:openDetails="openDetails"
      :title="item.fornecedor_nome"
      :img="getImages(item.imagens)"
      :category="item.categoria_nome"
      :titleButton="$t('global.select')"
      :description="item.fornecedor_nome"
      :value="toMoneyLabel(parseInt(item.preco))"
    />
  </div>
  <reward-confirmation-modal-component
    @submit="onSubmit()"
    v-model="confirmationDialog"
    :image="'/present-icon.svg'"
    :message="$t('my_points.reward_gift.final_confirmation')"
  />
</template>

<script>
import rewardConfirmationModalComponent from "@/modules/main/components/rewardConfirmationModalComponent.vue";
import { emailMatchValidation } from "@/shared/helpers/validationHelper";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import VCard from "@/modules/main/components/v-card";
import { ref, defineComponent } from "vue";

const { useActions } = createNamespacedHelpers("rescue_award"),
  { useGetters } = createNamespacedHelpers("auth");

export default defineComponent({
  name: "RewardItem",

  props: {
    item: {
      type: Object,
      required: true,
    },
    option: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    VCard,
    rewardConfirmationModalComponent,
  },

  setup(props) {
    const itemSelected = ref(null),
      isCompacted = ref({
        moreInformation: true,
        moreInformationAdditional: true,
        descriptionDetails: true,
      }),
      wasRead = ref(true),
      dialog = ref(false),
      confirmationDialog = ref(false),
      email = ref(""),
      confirmWrong = ref(false),
      confirmationWrong = ref(false),
      confirm = ref(""),
      ableSendButtom = ref([true, true]);

    const { emailHidden, userEmail } = useGetters({
        emailHidden: "userEmailHidden",
        userEmail: "userEmail",
      }),
      { rescueReward, fetchReward } = useActions([
        "rescueReward",
        "fetchReward",
      ]);

    const openModal = (item) => {
        itemSelected.value = item;
        dialog.value = true;
      },
      openDetails = (item) => {
        itemSelected.value = item;
        dialog.value = true;
      },
      canRescueValue = () => props.data.amount >= props.data.min_value;

    const onSubmit = () => {
      if (canRescueValue()) {
        let data = {
          partner_email: email.value,
          finantial_value: parseFloat(itemSelected.value.preco),
          amount: parseFloat(itemSelected.value.preco_btdi),
          reward_option_id: props.data.option.reward_option_id,
          external_id: itemSelected.value.codigo,
          quantity: 1,
        };
        rescueReward(data)
          .then(() => {
            fetchReward().then(() => {
              notifySuccess();
              window.location.reload();
            });
          })
          .catch(() => notifyError());
      }
    };

    const confirmModal = () => {
        if (wasRead.value == true) confirmationDialog.value = true;
      },
      informationAdditional = (info) => {
        let content = JSON.parse(info.informacoes_adicionais).Informacao;
        return typeof content == "object" ? [content] : content;
      };

    const descriptionDetails = (DescriptionDetails) =>
        JSON.parse(DescriptionDetails.detalhes_descricao),
      getImages = (images) =>
        images != null
          ? JSON.parse(images).g
          : "/awards-icons/img_not_found.png";

    const ableButtom1 = () => {
        if (ableSendButtom.value[0] == true) ableSendButtom.value[0] = false;
      },
      ableButtom2 = () => {
        if (ableSendButtom.value[1] == true) ableSendButtom.value[1] = false;
      };

    const ableSendButtomVerify = () =>
      ableSendButtom.value[0] == false && ableSendButtom.value[1] == false
        ? false
        : true;

    const returnButtonEnable = () => (ableSendButtom.value = [true, true]);

    const verifyIfExistsInfos = (data) => {
      if (JSON.parse(data.informacoes_adicionais).Informacao == undefined) {
        ableButtom1();
        return false;
      } else return true;
    };

    return {
      dialog,
      email,
      confirm,
      wasRead,
      userEmail,
      emailHidden,
      toMoneyLabel,
      itemSelected,
      confirmWrong,
      isCompacted,
      ableSendButtom,
      confirmationWrong,
      confirmationDialog,
      emailMatchValidation,
      onSubmit,
      getImages,
      openModal,
      ableButtom1,
      ableButtom2,
      confirmModal,
      openDetails,
      descriptionDetails,
      returnButtonEnable,
      ableSendButtomVerify,
      verifyIfExistsInfos,
      informationAdditional,
    };
  },
});
</script>

<style lang="scss" scoped>
.width-container {
  max-width: 60vw;
}
.image-align {
  text-align: center;
}
.image-width {
  width: 70%;
}
.confirm-area {
  border: 1px solid black;
  padding: 2em;
}
.button-style {
  padding: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.prod-title {
  font-size: 14pt;
}
.prod-description {
  width: 95%;
}

@media (max-width: 1284px) {
  .width-container {
    max-width: 100vw;
  }
  .image-align {
    text-align: center;
  }
  .image-width {
    width: 60%;
  }
  .prod-title {
    font-size: 12pt;
  }
}

@media (max-width: 600px) {
  .prod-description {
    width: 100%;
  }
}
</style>
