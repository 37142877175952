<template>
  <div
    class="
      dash-widget
      default-shadow default-rounded
      width-fit-content-flex
      bg-white
      q-pa-md
    "
  >
    <div class="width-fit-content-flex" v-if="skeleton">
      <div class="widget-header row">
        <div class=" text-ds-grey text-uppercase text-weight-medium">
          <p>{{ title }}</p>
        </div>
      </div>
      <div class="widget-content q-mt-lg">
        <q-spinner-orbit size="20px" color="grey" />
      </div>
    </div>
    <div class="width-fit-content-flex" v-else>
      <div class="widget-header row">
        <div class=" text-ds-grey text-uppercase text-weight-medium">
          {{ title }}
        </div>
      </div>
      <div class="widget-content q-mt-lg">
        <vue3-autocounter
          v-if="type === 'money'"
          class="text-counter-size"
          ref="counter"
          :startAmount="0"
          :endAmount="value"
          :prefix="prefix"
          :duration="1"
          separator="."
          decimalSeparator=","
          :decimals="2"
          :autoinit="true"
        />
        <vue3-autocounter
          v-if="type === 'number'"
          class="text-counter-size"
          ref="counter"
          :startAmount="0"
          :prefix="prefix"
          :endAmount="value"
          :duration="1"
          separator="."
          :decimals="2"
          :autoinit="true"
        />
        <div v-if="type === 'text'" class="widget-content q-mt-lg">
          <span class="text-counter-size">{{ value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3autocounter from "vue3-autocounter";

export default {
  name: "Widget",

  components: {
    "vue3-autocounter": Vue3autocounter,
  },

  props: {
    skeleton: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "number",
    },
    prefix: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
    },
    title: {
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
.dash-widget {
  padding: 18px;
  .widget-header {
    font-size: 11pt;
  }
}
.text-counter-size {
  font-size: 15pt !important;
  font-weight: lighter;
  color: rgb(34, 34, 34);
}
.mobile {
  .dash-widget {
    .text-small-info {
      font-size: 10px;
      margin: 10px;
    }
    .widget-footer {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
