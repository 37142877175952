<template>
  <q-card class="course-card justify-between full-height">
    <div class="row">
      <div class="col-12 col-lg-5 image-content">
        <img :src="img" class="course-card-image" alt="" />
      </div>
      <div class="col-12 col-lg-7">
        <q-card-section class="course-container-card-description">
          <div class="course-card-title2 text-uppercase text-grey-8">
            <span>{{ title }}</span>
          </div>

          <span style="font-size: 14pt" class="text-weight-bolder text-grey-8">
            {{ $t("my_points.awards.card_value", { value: value }) }}</span
          >
        </q-card-section>
        <div
          v-if="
            parseFloat(data.amount) < parseFloat(data.min_value) || disabled
          "
          class="row justify-center"
          style="width: 100%"
        >
          <q-btn
            color="black"
            class="button-select"
            :label="`${titleButton}`"
            disabled="true"
          />
        </div>

        <div v-else class="row justify-center" style="width: 100%">
          <q-btn
            color="black"
            :disabled="disabled"
            class="button-select"
            :label="`${titleButton}`"
            @click="$emit('openModal', item)"
          />
        </div>
      </div>
    </div>
  </q-card>
</template>

<script>
import { ref } from "vue";

export default {
  name: "Card",

  props: {
    title: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    let isCompacted = ref(true),
      windowSize = ref(parseInt(Window.innerWidth) < 700);

    return {
      isCompacted,
      windowSize,
    };
  },
};
</script>

<style lang="scss" scoped>
.course-card {
  padding: 1rem 1rem;
  margin: 10px;
  border-radius: 10px;
}

.course-card-title2 {
  font-weight: bold;
  font-size: 12pt;
}

.course-card-description,
.course-card-title {
  font-weight: bold;
  font-size: 12pt;
}

.show-more-btn {
  width: 100%;
  margin-top: 0.1rem;
  display: block;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  color: #033b8f;
}

.course-card-image {
  width: 80%;
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.button-select {
  border-radius: 10px;
}

@media (max-width: 1439px) {
  .course-card-image {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 110px;
  }
  .course-card-description,
  .course-card-title {
    font-weight: bold;
    font-size: 12pt;
  }
  .image-content {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .course-card-image {
    width: 150px;
  }
}
</style>
