<template>
  <page-title
    class="q-mb-lg"
    title-class
    :skeleton="false"
    :title="$t('my_points.my_points.title')"
  />
  <widgets-container />
  <rewards-opened-container />
  <RewardsContainer />
</template>
<script>
import RewardsOpenedContainer from "./components/last-request/RewardsOpenedContainer.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import WidgetsContainer from "./components/widgetsContainer";
import PageTitle from "../../components/page-title";
import RewardsContainer from "./components/RewardsContainer.vue";

const { useActions } = createNamespacedHelpers("rescue_award");

export default {
  components: {
    RewardsOpenedContainer,
    RewardsContainer,
    WidgetsContainer,
    PageTitle,
  },

  setup() {
    const { fetchReward } = useActions(["fetchReward"]);
    fetchReward();
  },
};
</script>
