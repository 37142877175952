<template>
  <div class="reward">
    <div
      class="
        reward-item
        no-wrap
        default-rounded default-shadow
        row
        justify-between
      "
    >
      <div class="reward-badge row justify-center flex-center">
        <div class="container">
          <q-img width="5em" :src="getImgByType(reward.reward_option_id)" />
        </div>
      </div>

      <div
        class="
          reward-item-info
          q-ma-lg
          width-100
          row
          flex-center
          justify-between
        "
      >
        <div class="order-details-item">
          <div class="text-weight-medium text-uppercase p-title">
            {{ reward.type_label }}
          </div>

          <div class="p-status">{{ created_date }}</div>
        </div>
        <div class="order-details-item">
          <div class="text-weight-medium text-grey-7">
            <strong>Créditos Utilizados:</strong>
            {{ reward.amount }}
          </div>
          <div class="text-weight-medium text-grey-7 q-mt-sm">
            <strong>Valor Final:</strong>
            {{ toMoneyLabel(reward.finantial_value) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { get } from "lodash";
import moment from "moment";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "AwardOpenedItem",

  props: {
    reward: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const created_date = moment(get(props.reward, "created_at")).format("LL");

    const { options } = useGetters({
      options: "getRewardOptions",
    });

    const getImgByType = () => {
      const item = options.value.find(
        (option) => option.reward_option_id === props.reward.reward_option_id
      );

      return item.image;
    };

    return {
      getImgByType,
      created_date,
      toMoneyLabel: toMoneyLabel,
    };
  },
};
</script>

<style scoped lang="scss">
.reward {
  &.AAP,
  &.CAN {
    opacity: 0.6;
  }
  &.CAN {
    .p-title {
      text-decoration: line-through;
    }
    .p-status {
      color: var(--q-negative) !important;
    }
  }
  .reward-item {
    background-color: white;
    .reward-badge {
      max-width: 180px;
      width: 100%;
      background: #cccccc;
      border-radius: 10px;
      font-weight: 400;
      min-width: 134px;
      text-align: center;
      color: aliceblue;
    }
  }
  .reward-item-info {
    .p-title {
      color: #2f3235;
    }
    .p-status {
      font-weight: 500;
      font-size: 13px;
      margin-top: 10px;
      color: #666666;
    }
  }
  .control {
    .button-chevron {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0 25px;
    }
  }
  .details {
    background: #eeeeee;
    padding: 20px;
    margin-top: -20px;
    .content {
      margin-top: 3px;
      border-top: 1px solid #e4e4e4;
      position: relative;
      .d-badge {
        position: absolute;
        left: -140px;
        top: 10px;
        font-weight: 400;
      }
    }
  }
}
.mobile {
  .reward {
    .reward-item {
      .reward-badge {
        font-size: 10px;
        min-width: 80px;
      }
    }
    .order-details-item {
      font-size: 12px;
    }
    .details {
      .content {
        margin-left: 0;
      }
    }
  }
}
</style>
