<template>
  <div class="row">
    <div class="width-fit-content-flex row">
      <div class="col-12 col-md-4">
        <widget
          class="q-ma-sm"
          :skeleton="in_request"
          type="number"
          :value="total"
          :title="$t('my_points.award.widget_total_title')"
        />
      </div>
      <div class="col-12 col-md-4">
        <widget
          class="q-ma-sm"
          :skeleton="in_request"
          type="text"
          :value="conversion"
          :title="$t('my_points.award.widget_conversion_title')"
        />
      </div>
      <div class="col-12 col-md-4">
        <widget
          class="q-ma-sm"
          :skeleton="in_request"
          type="money"
          prefix="R$ "
          :value="bonus"
          :title="$t('my_points.award.widget_bonus_title')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Widget from "./widget";

const { useGetters } = createNamespacedHelpers("rescue_award");

export default {
  name: "WidgetsContainer",

  components: { Widget },

  setup() {
    const { in_request, total, conversion, bonus } = useGetters({
      in_request: "isInRequest",
      total: "getTotalPointWidget",
      conversion: "getConversionWidget",
      bonus: "getBonusWidget",
    });

    return {
      in_request,
      total,
      conversion,
      bonus,
    };
  },
};
</script>
