<template>
  <div class="row">
    <div class="col-12 q-pa-md bg-yellow-4 text-justify">
      <q-icon name="warning" style="font-size: 16pt" class="q-pr-sm" />
      <span v-html="$t('my_points.reward_pre_paid_card.disclaimer2')" />
    </div>
    <q-card flat class="col-12 q-pa-md">
      <div align="right" class="text-blue-7  q-pr-md">
        <router-link class="linkStyle" :to="{ name: 'account.personal-info' }">
          <q-icon name="edit" /> {{ $t("my_points.reward_pre_paid_card.edit") }}
        </router-link>
      </div>
      <div v-if="partner.pf_pj == 'PF'" class="textStyle row">
        <div class="col-12 col-md-6 ">
          <template v-for="(item, i) in pfPartner" :key="i">
            <p
              class="q-mb-none"
              v-html="
                $t(`my_points.reward_pre_paid_card.pf.${i}`, {
                  value: item || '--',
                })
              "
            />
          </template>
        </div>
        <div class="col-12 col-md-6">
          <template v-for="(item, i) in address" :key="i">
            <p
              class="q-mb-none"
              v-html="
                $t(`my_points.reward_pre_paid_card.address.${i}`, {
                  value: item || '--',
                })
              "
            />
          </template>
        </div>
      </div>
      <div v-if="partner.pf_pj == 'PJ'" class="col-12 col-md-6 ">
        <div class="q-pa-sm">
          <template v-for="(item, i) in pjPartner" :key="i">
            <p
              class="q-mb-none"
              v-html="
                $t(`my_points.reward_pre_paid_card.pf.${i}`, {
                  value: item || '--',
                })
              "
            />
          </template>
        </div>
        <div class="col-12 col-md-6">
          <template v-for="(item, i) in address" :key="i">
            <p
              class="q-mb-none"
              v-html="
                $t(`my_points.reward_pre_paid_card.address.${i}`, {
                  value: item || '--',
                })
              "
            />
          </template>
        </div>
      </div>
    </q-card>
  </div>
  <div
    v-if="!elegible_data.elegible"
    class="q-mt-md q-pa-md q-mb-md bg-red-4 text-white text-justify row no-wrap"
  >
    <q-icon name="info" style="font-size: 16pt" class="q-pr-sm" />
    <div v-html="elegible_data.message" />
  </div>
</template>

<script>
import { useGetters } from "vuex-composition-helpers";
import { defineComponent } from "vue";

export default defineComponent({
  name: "prePaidCardInformation",

  props: {
    elegible_data: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { partner } = useGetters({
      partner: "account/getPartnerAccount",
    });

    const pfPartner = {
      name: partner.value.nome,
      cpf: partner.value.pf_cpf,
      gender: partner.value.sexo == 1 ? "Masculino" : "Feminino",
      celular: partner.value.celular,
      email: partner.value.email_principal,
    };

    const pjPartner = {
      social: partner.value.pj_razaosocial,
      cnpj: partner.value.pj_cnpj,
      email: partner.value.email_principal,
      celular: partner.value.celular,
    };

    const address = {
      cep: partner.value.end_cep,
      street: partner.value.end_rua,
      number: partner.value.end_numero,
      district: partner.value.end_bairro,
      complement: partner.value.end_complemento,
      stateAndcity:
        partner.value.end_cidade == null || partner.value.end_uf == null
          ? "--"
          : partner.value.end_cidade + " - " + partner.value.end_uf,
    };

    return {
      address,
      pjPartner,
      pfPartner,
      partner,
    };
  },
});
</script>

<style scoped>
.linkStyle {
  color: rgb(14, 129, 243);
  text-decoration: none;
}
.textStyle {
  font-size: 12pt;
}
</style>
