<template>
  <q-card-section
    v-if="group == 0"
    class="justify-center q-ml-md q-mr-md q-mb-sm bg-grey-3"
    style="border-radius: 10px"
  >
    <div
      class="bg-white q-pa-md q-mb-md q-mt-sm text-weight-bolder text-center"
    >
      <p
        class="q-mb-none"
        v-html="
          $t('my_points.reward_pre_paid_card.calc_taxes1', {
            value_1: toMoneyLabel(selectedOption.preco_btdi),
            value_2: selectedOption.preco,
            value_3: toMoneyLabel(selectedOption.frete),
            value_4: toMoneyLabel(
              getTax(
                selectedOption.preco_btdi,
                selectedOption.preco,
                selectedOption.frete
              )
            ),
          })
        "
      />
    </div>
    <prePaidCardInformation :elegible_data="getPrePaidCardElegible" />
    <q-form @submit.prevent="onSubmit(selectedOption)">
      <q-toggle
        v-model="wasRead"
        :label="$t('my_points.reward_gift.confirm_check')"
      /><br />
      <p
        v-if="wasRead == false"
        style="font-size: 9pt"
        class="text-red-8 q-mt-sm q-ml-lg"
        v-html="$t('my_points.reward_gift.alerts.alert_check')"
      />
      <div align="right">
        <q-btn
          color="black"
          type="submit"
          :disable="
            !wasRead ||
              !getPrePaidCardElegible.elegible ||
              doc_pend.unfilled == 1
          "
          class="button-style"
          :label="$t('resgatar')"
        />
        <q-btn
          flat
          color="primary"
          class="q-ml-sm button-style"
          :label="$t('global.cancel')"
          @click="cardNumber = ''"
          v-close-popup
        />
      </div>
    </q-form>
  </q-card-section>
</template>

<script>
import prePaidCardInformation from "./prePaidCardInformation.vue";

import { checkPrePaidCardNumber } from "@/shared/helpers/validationHelper";
import { toMoneyLabel } from "@/shared/helpers/numbersManipulation";
import { useGetters } from "vuex-composition-helpers";
import { ref } from "vue";

export default {
  name: "prepaidRescue",

  components: { prePaidCardInformation },

  props: {
    option: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    doc_pend: {
      type: Object,
      required: true,
    },
    selectedOption: {
      type: Object,
      required: true,
    },
  },

  emits: ["submitForm"],

  setup(props, { emit }) {
    const { partner, getPrePaidCardElegible, getPrePaidCardInfo } = useGetters({
      partner: "account/getPartnerAccount",
      getPrePaidCardElegible: "rescue_award/getPrePaidCardElegible",
      getPrePaidCardInfo: "rescue_award/getPrePaidCardInfo",
    });

    const load = ref(false),
      group = ref(0),
      cardNumber = ref(""),
      wasRead = ref(true),
      confirm = ref(""),
      confirmationDialog = ref(false);

    const getImages = (images) =>
      images != null ? JSON.parse(images).g : "/awards-icons/img_not_found.png";

    const onSubmit = (selected) => {
      let data = {
        quantity: 1,
        external_id: selected.codigo,
        amount: parseFloat(selected.preco_btdi),
        finantial_value: parseFloat(selected.preco),
        partner_email: partner.value.email_principal,
        reward_option_id: props.data.option.reward_option_id,
      };

      emit("submitForm", data);
    };

    const getTax = (preco_btdi, preco, frete) =>
      parseFloat(preco_btdi) - (parseFloat(preco) + parseFloat(frete));

    return {
      load,
      group,
      confirm,
      wasRead,
      cardNumber,
      toMoneyLabel,
      confirmationDialog,
      getPrePaidCardInfo,
      checkPrePaidCardNumber,
      getPrePaidCardElegible,
      onSubmit,
      getImages,
      getTax,
    };
  },
};
</script>

<style lang="scss" scoped>
.width-container {
  min-width: 60vw;
}
.image-align {
  text-align: center;
}
.image-width {
  width: 70%;
  margin-top: -10px;
}

@media (max-width: 800px) {
  .width-container {
    min-width: 95vw;
  }
  .prod-description {
    width: 100%;
  }
}
</style>
