<template>
  <div class="q-ma-sm bg-white q-pa-md border-radius" v-show="show_infos">
    <q-bar class="text-black bg-white q-mb-md">
      <q-space />
      <q-btn flat icon="close" class="text-red" @click="show_infos = false" />
    </q-bar>
    <div class="margin-infos">
      <span class="text-information"
        ><q-icon name="done" />
        {{ $t("my_points.reward_gift.information.text_1") }}</span
      >
      <span class="text-information"
        ><q-icon name="done" />
        {{ $t("my_points.reward_gift.information.text_2") }}</span
      >
      <span class="text-information"
        ><q-icon name="done" />
        {{ $t("my_points.reward_gift.information.text_3") }}</span
      >
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "reward-card-information",

  props: {
    data: {
      type: String,
      required: true,
    },
  },

  setup() {
    const show_infos = ref(true);
    return {
      show_infos,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-information {
  display: block;
  font-size: 11pt;
}
.margin-infos {
  margin-top: -3em;
}
.border-radius {
  border-radius: 10px;
}

@media (max-width: 1280px) {
  .text-information {
    display: block;
    font-size: 9.5pt;
  }
}
@media (max-width: 700px) {
  .margin-infos {
    margin-top: -2em;
  }
}
</style>
